import React, {useState} from 'react';
import avatar from "../assets/image/avatar.png";
import pdfIcon from "../assets/image/pdf_icon.png";

const IconRenderer = ({data, type}) => {
  return(
    <div className="flex justify-center items-center">
      {type === 'IconUpload' && <img
        src={data.IconUpload || avatar}
        alt="..."
        className="w-9 h-9 rounded-full"
      />}
      {type === 'Bio' && data.Bio ? <img
        src={data.Bio}
        alt="..."
        className="w-9 h-9"
      /> : <div></div>}
      {type === 'Sample1' && data.SampleImg1 ? <img
        src={data.SampleImg1}
        alt="..."
        className="w-9 h-9"
      /> : <div></div>}
      {type === 'Sample2' && data.SampleImg2 ? <img
        src={data.SampleImg2}
        alt="..."
        className="w-9 h-9"
      /> : <div></div>}
      {type === 'Sample3' && data.SampleImg3 ? <img
        src={data.SampleImg3}
        alt="..."
        className="w-9 h-9"
      /> : <div></div>}
      {type === "File1" && data.Upload && data.Upload.file1.includes("image") && (
        <img
          src={data.Upload.file1}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {type === "File1" && data.Upload && data.Upload.file1.includes("video") && (
        <video
          autoPlay
          muted
          loop
          className="h-9 w-9"
        >
          <source src={data.Upload.file1} type="video/mp4" />
        </video>
      )}
      {type === "File1" && data.Upload && data.Upload.file1.includes("pdf") && (
        <img
          src={pdfIcon}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {(!data.Upload || !data.Upload.file1) && <div></div>}

      {type === "File2" && data.Upload && data.Upload.file2.includes("image") && (
        <img
          src={data.Upload.file2}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {type === "File2" && data.Upload && data.Upload.file2.includes("video") && (
        <video
          autoPlay
          muted
          loop
          className="h-9 w-9"
        >
          <source src={data.Upload.file2} type="video/mp4" />
        </video>
      )}
      {type === "File2" && data.Upload && data.Upload.file2.includes("pdf") && (
        <img
          src={pdfIcon}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {(!data.Upload || !data.Upload.file2) && <div></div>}

      {type === "File3" && data.Upload && data.Upload.file3.includes("image") && (
        <img
          src={data.Upload.file3}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {type === "File3" && data.Upload && data.Upload.file3.includes("video") && (
        <video
          autoPlay
          muted
          loop
          className="h-9 w-9"
        >
          <source src={data.Upload.file3} type="video/mp4" />
        </video>
      )}
      {type === "File3" && data.Upload && data.Upload.file3.includes("pdf") && (
        <img
          src={pdfIcon}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {(!data.Upload || !data.Upload.file3) && <div></div>}

      {type === "File4" && data.Upload && data.Upload.file4.includes("image") && (
        <img
          src={data.Upload.file4}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {type === "File4" && data.Upload && data.Upload.file4.includes("video") && (
        <video
          autoPlay
          muted
          loop
          className="h-9 w-9"
        >
          <source src={data.Upload.file4} type="video/mp4" />
        </video>
      )}
      {type === "File4" && data.Upload && data.Upload.file4.includes("pdf") && (
        <img
          src={pdfIcon}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {(!data.Upload || !data.Upload.file4) && <div></div>}

      {type === "File5" && data.Upload && data.Upload.file5.includes("image") && (
        <img
          src={data.Upload.file5}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {type === "File5" && data.Upload && data.Upload.file5.includes("video") && (
        <video
          autoPlay
          muted
          loop
          className="h-9 w-9"
        >
          <source src={data.Upload.file5} type="video/mp4" />
        </video>
      )}
      {type === "File5" && data.Upload && data.Upload.file5.includes("pdf") && (
        <img
          src={pdfIcon}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {(!data.Upload || !data.Upload.file5) && <div></div>}

      {type === "File6" && data.Upload && data.Upload.file6.includes("image") && (
        <img
          src={data.Upload.file6}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {type === "File6" && data.Upload && data.Upload.file6.includes("video") && (
        <video
          autoPlay
          muted
          loop
          className="h-9 w-9"
        >
          <source src={data.Upload.file6} type="video/mp4" />
        </video>
      )}
      {type === "File6" && data.Upload && data.Upload.file6.includes("pdf") && (
        <img
          src={pdfIcon}
          alt="..."
          className="h-9 w-9 object-contain"
        />
      )}
      {(!data.Upload || !data.Upload.file6) && <div></div>}
    </div>
  )
}

export default IconRenderer;
