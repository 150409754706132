import React from "react";
import Navbar from "../components/Navbar";

const AssistantPage = () => {
  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col py-4 px-20">
        <p className="text-lg font-bold underline">How It Works</p>
        <p className="mt-3">
          SubWorkNow.com is a platform designed to connect small to mid-sized
          General Contractors needing specific jobs to be performed with
          qualified and available Subcontractors.
        </p>
        <p className="mt-3">The process is very simple.</p>
        <p className="mt-3">
          General Contractors simply register then follow the steps under the
          “Post a Job” Tab in order to list specific jobs that need to be
          completed. General Contractors will be notified by app notification
          and/or email when a Subcontractor places a “bid”. The General
          Contractor can then review the bid including the Bidder’s profile and
          qualifications. Upon selection, the General Contractor can coordinate
          a walk through or other documentation if necessary before job
          performance begins. Please note SubWorkNow does not provide any
          specific contracting services between parties at this time but the
          provided messaging system may provide assistance in executing such
          matters.
        </p>
        <p className="mt-3">
          Subcontracts can look for applicable posted “jobs” and make desired
          bids. Likewise, Subcontractors may be notified by app notification
          and/or bid of any open applicable “posted jobs”. Upon selection by the
          General Contractor a walk though or other documentation if necessary
          may be conducted prior to job performance. Please note SubWorkNow does
          not provide any specific contracting services between parties at this
          time but the provided messaging system may provide assistance in
          executing such matters.
        </p>
        <p className="mt-3">
          This process will provide efficiency and time savings for both General
          Contractors and Subcontractors.
        </p>
        <p className="mt-3">
          Any questions or concerns may be made to&nbsp;
          <a
            className="text-blue-500 underline"
            href="mailto:rey@subworknow.com"
          >
            rey@subworknow.com
          </a>
          &nbsp;or by calling&nbsp;
          <a className="text-blue-500 underline" href="tel:1-844-SUBWORK1">
            1-844-SUBWORK1
          </a>
          . We are available and happy to assist.
        </p>
        <p className="text-lg font-bold underline mt-5">
          General Contractor Job Posting Cancellation
        </p>
        <p className="mt-3">
          Any General Contractor Posted Job can be removed or deleted at any
          time by simply going to the “My Jobs” Section and deleting such
          posting.
        </p>
        <p className="mt-3">
          Any questions or concerns may be made to&nbsp;
          <a
            className="text-blue-500 underline"
            href="mailto:rey@subworknow.com"
          >
            rey@subworknow.com
          </a>
          &nbsp;or by calling&nbsp;
          <a className="text-blue-500 underline" href="tel:1-844-SUBWORK1">
            1-844-SUBWORK1
          </a>
          . We are available and happy to assist.
        </p>
        <p className="text-lg font-bold underline mt-5">Updating Profile</p>
        <p className="mt-3">
          Any General Contractor or Subcontractor can update their profile by
          simply selecting the “Profile” button and updating the reflected
          information.
        </p>
        <p className="mt-3">
          Any questions or concerns may be made to&nbsp;
          <a
            className="text-blue-500 underline"
            href="mailto:rey@subworknow.com"
          >
            rey@subworknow.com
          </a>
          &nbsp;or by calling&nbsp;
          <a className="text-blue-500 underline" href="tel:1-844-SUBWORK1">
            1-844-SUBWORK1
          </a>
          . We are available and happy to assist.
        </p>
        <p className="text-lg font-bold underline mt-5">Direct Messaging</p>
        <p className="mt-3">
          Any General Contractor and Subcontractor regardless of selection may
          correspond and communicate with each other via the provided messaging
          system. This system is similar to what many would consider text
          messaging.
        </p>
        <p className="mt-3">
          Any questions or concerns may be made to&nbsp;
          <a
            className="text-blue-500 underline"
            href="mailto:rey@subworknow.com"
          >
            rey@subworknow.com
          </a>
          &nbsp;or by calling&nbsp;
          <a className="text-blue-500 underline" href="tel:1-844-SUBWORK1">
            1-844-SUBWORK1
          </a>
          . We are available and happy to assist.
        </p>
      </div>
    </div>
  );
};

export default AssistantPage;
