import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { database } from "../providers/firebase";
import Modal from "react-modal";
import IconRenderer from "../components/IconRenderer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import ServicesRenderer from "../components/ServicesRenderer";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const defaultColDef = {
  resizable: true,
  editable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  valueSetter: (params) => {
    params.data[params.colDef.field] = params.newValue;
    return params.newValue !== params.oldValue;
  },
  valueGetter: (params) => params.data[params.colDef.field],
};

const columnTypes = {
  nonEditableColumn: { editable: false },
};

const rowHeight = 44;

const SCUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [newUid, setNewUid] = useState("");
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen1, setIsOpen1] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [message, setMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      let scUsers = [];
      const snapshot = await database.ref(`SCT`).once("value");
      if (!!snapshot.val()) {
        snapshot.forEach((child) => {
          scUsers.push(child.val());
        });
      }

      scUsers.sort((a, b) =>
        a.JoinedAt < b.JoinedAt ? 1 : b.JoinedAt < a.JoinedAt ? -1 : 0
      );
      setUsers(scUsers);
    }

    fetchData();
  }, [newUid]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onCellValueChanged = (event) => {
    if (event.data.UserUid) {
      const scUserRef = database.ref(`SCT/${event.data.UserUid}`);
      scUserRef.update(event.data);
    } else if (event.data.Email) {
      axios
        .post(
          "https://us-central1-subworknow.cloudfunctions.net/addUser",
          { email: event.data.Email, password: "123456" },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(async function (response) {
          console.log("response", response);
          if (response.data.error) {
            toast(response.data.error.message);
            return;
          }
          const scUserRef = database.ref(`SCT/${response.data.data.uid}`);
          await scUserRef.set({
            UserUid: response.data.data.uid,
            Email: event.data.Email,
            JoinedAt: new Date().toISOString(),
          });
          setNewUid(response.data.data.uid);
          toast("The new user has been created.");
        })
        .catch(function (error) {
          console.log("error", error);
        });
    }
  };

  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    setSelectedRows(selectedRows);
  };

  const onHideUsers = async () => {
    selectedRows.forEach(async (row) => {
      const key = row.UserUid;

      if (key) {
        await database.ref(`SCT/${key}`).update({ hidden: true });
        toast("This user is hidden now.");
      }
    });
  };

  const onViewProfile = async () => {
    history.push({
      pathname: "viewsc",
      state: { profile: selectedRows[0] },
    });
  };

  const onSendSMS = async () => {
    setIsOpen2(false);

    selectedRows.forEach(async (row) => {
      console.log("row", row);
      const snap = await database.ref(`SCT/${row.UserUid}`).once("value");
      const phoneNo = snap.val().Phone;

      if (phoneNo) {
        axios
          .post(
            "https://us-central1-subworknow.cloudfunctions.net/sendSMS1",
            { to: phoneNo, body: message },
            { headers: { "Content-Type": "application/json" } }
          )
          .then(async function (response) {
            console.log("response", response);
          })
          .catch(function (error) {
            console.log("error", error);
          });
      } else {
        toast("This user has no phone number.");
      }
    });
  };

  const onOpenSMSModal = async () => {
    setIsOpen2(true);
  };

  const onPressDelete = async () => {
    gridApi.applyTransaction({ remove: selectedRows });

    let bids = [];
    const snapshot = await database.ref(`BT`).once("value");
    if (!!snapshot.val()) {
      snapshot.forEach((child) => {
        bids.push(child.val());
      });
    }

    let offers = [];
    const snapshot1 = await database.ref(`OT`).once("value");
    if (!!snapshot1.val()) {
      snapshot1.forEach((child) => {
        offers.push(child.val());
      });
    }

    selectedRows.forEach((row) => {
      const key = row.UserUid;
      database.ref(`SCT/${key}`).remove();

      const filterBids = bids.filter((bid) => bid.UserUid === key);
      if (filterBids && filterBids.length > 0) {
        filterBids.forEach((bid) => {
          database.ref(`BT/${bid.BidId}`).remove();
        });
      }

      const filterOffers = offers.filter((offer) => offer.SCUid === key);
      if (filterOffers && filterOffers.length > 0) {
        filterOffers.forEach((offer) => {
          database.ref(`OT/${offer.OfferId}`).remove();
        });
      }
    });
    setIsOpen(false);
  };

  const onTransferUsers = () => {
    setIsOpen1(true);
  };

  const onPressTransfer = () => {
    setIsOpen1(false);
    selectedRows.forEach(async (row) => {
      console.log("row", row);
      const key = row.UserUid;
      const gcUserRef = database.ref(`GCT/${key}`);
      await gcUserRef.set({
        Address: row.Address,
        Email: row.Email,
        IconUpload: row.IconUpload,
        JoinedAt: row.JoinedAt,
        LegalEntity: row.LegalEntityName,
        Phone: row.Phone,
        PlayerId: row.PlayerId,
        SecondEmail: row.SecondEmail,
        UserUid: key,
        fcmToken: row.fcmToken,
        NotifyJobs: row.NotifyJobs,
      });
    });

    onPressDelete();
  };

  const onDeleteUsers = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const onCloseModal1 = () => {
    setIsOpen1(false);
  };

  const onCloseModal2 = () => {
    setIsOpen2(false);
  };

  const onPressAdd = () => {
    var newItems = [[]];
    var res = gridApi.applyTransaction({
      add: newItems,
      addIndex: 0,
    });
  };

  return (
    <div className="w-full">
      <Navbar />
      <p className="text-center font-bold mb-4">Sub-Contractors</p>
      <div className="ag-theme-alpine w-full h-96">
        <AgGridReact
          rowData={users}
          rowHeight={rowHeight}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          // rowSelection={"multiple"}
          rowMultiSelectWithClick={true}
          pivotPanelShow={"always"}
          paginationAutoPageSize={true}
          pagination={true}
          onCellValueChanged={onCellValueChanged}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
        >
          <AgGridColumn
            field="UserUid"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            checkboxSelection={true}
            width={150}
          ></AgGridColumn>
          <AgGridColumn
            field="Email"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field="LegalEntityName"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          {/* <AgGridColumn field="Address"></AgGridColumn> */}
          <AgGridColumn
            field="LicenseNumber"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field="PointOfContact"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn field="Address"></AgGridColumn>
          <AgGridColumn field="SecondEmail"></AgGridColumn>
          <AgGridColumn field="Phone" width={150}></AgGridColumn>
          <AgGridColumn field="Website"></AgGridColumn>
          <AgGridColumn field="SocialMedia"></AgGridColumn>
          <AgGridColumn
            field="Location"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field="Bio"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{ data: {}, type: "Bio" }}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="References"
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="SampleImg1"
            headerName="Img1"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{ data: {}, type: "Sample1" }}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="SampleImg2"
            headerName="Img2"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{ data: {}, type: "Sample2" }}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="SampleImg3"
            headerName="Img3"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{ data: {}, type: "Sample3" }}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="SelectQualifiedService"
            sortable={true}
            filter={true}
            cellRendererFramework={ServicesRenderer}
            cellRendererParams={{ data: {} }}
          ></AgGridColumn>
          <AgGridColumn
            field="NotifyJobs"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="JoinedAt"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="Coins"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="PlayerId"
            type="nonEditableColumn"
            width={150}
          ></AgGridColumn>
          <AgGridColumn
            field="fcmToken"
            type="nonEditableColumn"
            width={150}
          ></AgGridColumn>
        </AgGridReact>
      </div>
      <div className="flex items-center justify-evenly">
        <button
          className={`w-48 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onPressAdd}
        >
          Add New User
        </button>
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onDeleteUsers}
          disabled={selectedRows.length === 0}
        >
          Delete Selected User
        </button>
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onTransferUsers}
          disabled={selectedRows.length === 0}
        >
          Transfer to GC
        </button>
      </div>
      <div className="flex items-center justify-evenly">
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onHideUsers}
          disabled={selectedRows.length === 0}
        >
          Hide Selected User
        </button>
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onViewProfile}
          disabled={selectedRows.length === 0}
        >
          View Profile
        </button>
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onOpenSMSModal}
          disabled={selectedRows.length === 0}
        >
          Send SMS
        </button>
      </div>
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Do you really want to delete the selected user?
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onPressDelete}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Modal isOpen={modalIsOpen1} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Do you really want to transfer this user to GC?
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onPressTransfer}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal1}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Modal isOpen={modalIsOpen2} style={customStyles} ariaHideApp={false}>
        <p className="text-md">Message:</p>
        <textarea
          className="mt-2 p-2 w-40 md:w-96 h-36 border border-black-400 mb-4"
          name="proposal"
          value={message}
          placeholder=""
          id="proposal"
          onChange={(event) => setMessage(event.currentTarget.value)}
        />
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onSendSMS}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal2}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default SCUsersPage;
