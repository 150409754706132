import React  from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import avatar from "../assets/image/avatar.png";

const ViewSCPage = () => {
  const {
    state: { profile },
  } = useLocation();
  const history = useHistory();

  const licenseNumber = profile.LicenseNumber;
  const legalEntityName = profile.LegalEntityName;
  const pointOfContact = profile.PointOfContact;
  const secondEmail = profile.SecondEmail;
  const phone = profile.Phone;
  const website = profile.Website;
  const socialMedia = profile.SocialMedia;
  const references = profile.References;
  const location = profile.Location;
  const icon = profile.IconUpload;
  const bio = profile.Bio;
  const sampleImg1 = profile.SampleImg1;
  const sampleImg2 = profile.SampleImg2;
  const sampleImg3 = profile.SampleImg3;

  const onPress = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <div className="w-full flex flex-col">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        <img
          src={icon ? icon : avatar}
          alt="..."
          className="w-32 h-32 rounded-full"
        />
      </div>
      <div className="mx-auto w-11/12 md:w-2/4 rounded py-4 px-4 md:px-8 flex flex-col">
        <label htmlFor="licenseNumber" className="block text-sm">
          License Number:
        </label>
        <input
          type="text"
          className="my-2 p-1 w-full border border-black-400"
          name="licenseNumber"
          value={licenseNumber}
          placeholder=""
          id="licenseNumber"
          readOnly
        />
        <label htmlFor="legalEntity" className="block text-sm">
          Legal Entity Name:
        </label>
        <input
          type="text"
          className="my-2 p-1 w-full border border-black-400"
          name="legalEntity"
          value={legalEntityName}
          placeholder=""
          id="legalEntity"
          readOnly
        />
        <label htmlFor="pointOfContact" className="block text-sm">
          Point Of Contact:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="pointOfContact"
          value={pointOfContact}
          placeholder=""
          id="pointOfContact"
          readOnly
        />
        <label htmlFor="secondEmail" className="block text-sm">
          Second Email Address:
        </label>
        <input
          type="email"
          className="my-1 p-1 w-full border border-black-400"
          name="secondEmail"
          value={secondEmail}
          placeholder=""
          id="secondEmail"
          readOnly
        />
        <label htmlFor="phone" className="block text-sm">
          Phone Number:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="phone"
          value={phone}
          placeholder=""
          id="phone"
          readOnly
        />
        <label htmlFor="website" className="block text-sm">
          Website:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="website"
          value={website}
          placeholder=""
          id="website"
          readOnly
        />
        <label htmlFor="socialMedia" className="block text-sm">
          Social Media:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="socialMedia"
          value={socialMedia}
          placeholder=""
          id="socialMedia"
          readOnly
        />
        {bio && (
          <>
            <label htmlFor="bio" className="block text-sm">
              Bio / Resume:
            </label>
            <div className="flex justify-center mt-3">
              <img
                src={bio}
                alt="..."
                className="border rounded-lg h-40 w-40"
              />
            </div>
          </>
        )}
        <label htmlFor="references" className="block text-sm">
          References:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="references"
          value={references}
          placeholder=""
          id="references"
          readOnly
        />
        {sampleImg1 && (
          <>
            <label htmlFor="sampleImg1" className="block text-sm">
              Sample Work Image 1:
            </label>
            <div className="flex justify-center mt-3">
              <img
                src={sampleImg1}
                alt="..."
                className="border rounded-lg h-40 w-40"
              />
            </div>
          </>
        )}
        {sampleImg2 && (
          <>
            <label htmlFor="sampleImg1" className="block text-sm">
              Sample Work Image 2:
            </label>
            <div className="flex justify-center mt-3">
              <img
                src={sampleImg2}
                alt="..."
                className="border rounded-lg h-40 w-40"
              />
            </div>
          </>
        )}
        {sampleImg3 && (
          <>
            <label htmlFor="sampleImg3" className="block text-sm">
              Sample Work Image 3:
            </label>
            <div className="flex justify-center mt-3">
              <img
                src={sampleImg3}
                alt="..."
                className="border rounded-lg h-40 w-40"
              />
            </div>
          </>
        )}
        <label htmlFor="location" className="block text-sm">
          City / Metro Area Served:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="location"
          value={location}
          placeholder=""
          id="location"
          readOnly
        />
        <button
          className={`w-24 bg-primary text-white py-2 my-3 rounded-full focus:outline-none self-center`}
          onClick={(event) => {
            onPress(event);
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ViewSCPage;
