import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { database } from "../providers/firebase";
import Modal from "react-modal";
import JobHiddenRenderer from "../components/JobHiddenRenderer";
import LocationRenderer from "../components/LocationRenderer";
import IconRenderer from "../components/IconRenderer";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const defaultColDef = {
  resizable: true,
  editable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  valueSetter: (params) => {
    params.data[params.colDef.field] = params.newValue;
    return params.newValue !== params.oldValue;
  },
  valueGetter: (params) => params.data[params.colDef.field],
};

const columnTypes = {
  nonEditableColumn: { editable: false },
};

const rowHeight = 44;

const JobsAdminPage = () => {
  const [jobs, setJobs] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let tempJobs = [];
      const snapshot = await database.ref(`JT`).once("value");
      if (!!snapshot.val()) {
        snapshot.forEach((child) => {
          tempJobs.push(child.val());
        });
      }

      tempJobs.sort((a,b) => (a.Timestamp < b.Timestamp) ? 1 : ((b.Timestamp < a.Timestamp) ? -1 : 0));

      let resultJobs = [];
      for (let job of tempJobs) {
        if (job.gLegal) {
          resultJobs.push(job);
        } else {
          let snapshot1 = null;
          const snap = await database.ref(`GCT/${job.UserUid}`).once("value");
          if (!!snap.val()) {
            snapshot1 = snap;
          } else {
            snapshot1 = await database.ref(`RT/${job.UserUid}`).once("value");
          }
          const gLegal = snapshot1.val() ? snapshot1.val().LegalEntity : '';
          resultJobs.push({...job, gLegal});
        }
      }
      setJobs(resultJobs);
    }

    fetchData();
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onCellValueChanged = (event) => {
    const jobRef = database.ref(`JT/${event.data.JobId}`);
    delete event.data['isHidden'];
    delete event.data['Location'];
    jobRef.update(event.data);
  };

  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    setSelectedRows(selectedRows);
  };

  const onPressDelete = async () => {
    gridApi.applyTransaction({ remove: selectedRows });

    let bids = [];
    const snapshot = await database.ref(`BT`).once("value");
    if (!!snapshot.val()) {
      snapshot.forEach((child) => {
        bids.push(child.val());
      });
    }

    let offers = [];
    const snapshot1 = await database.ref(`OT`).once("value");
    if (!!snapshot1.val()) {
      snapshot1.forEach((child) => {
        offers.push(child.val());
      });
    }

    selectedRows.forEach(async (row) => {
      const key = row.JobId;
      database.ref(`JT/${key}`).remove();

      const filterBids = bids.filter(bid => bid.JobId === key);
      if (filterBids && filterBids.length > 0) {
        filterBids.forEach(bid => {
          database.ref(`BT/${bid.BidId}`).remove();
          const filterOffer = offers.find(offer => offer.BidId === bid.BidId);
          if (filterOffer) {
            database.ref(`OT/${filterOffer.OfferId}`).remove();
          }
        })
      }
    });
    setIsOpen(false);
  };

  const onHideJobs = () => {
    selectedRows.forEach((row) => {
      const rowNode = gridApi.getRowNode(row.JobId);
      rowNode.setData({ ...rowNode.data, isHidden: true });

      const key = row.JobId;
      const jobRef = database.ref(`JT/${key}`);
      jobRef.update({
        isHidden: true,
      });
    });
  };

  const onDeleteJobs = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  // const onPressAdd = (e) => {
  //   e.preventDefault();
  //   var newItems = [[]];
  //   var res = gridApi.applyTransaction({
  //     add: newItems,
  //   });
  // }

  return (
    <div className="w-full h-screen">
      <Navbar />
      <p className="text-center font-bold mb-4">Posted Jobs</p>
      <div className="ag-theme-alpine w-full h-3/5">
        <AgGridReact
          rowData={jobs}
          rowHeight={rowHeight}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          rowSelection={"multiple"}
          rowMultiSelectWithClick={true}
          pivotPanelShow={"always"}
          paginationAutoPageSize={true}
          pagination={true}
          onCellValueChanged={onCellValueChanged}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
          getRowNodeId={(data) => data.JobId}
        >
          <AgGridColumn
            field="JobId"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            checkboxSelection={true}
            width={150}
          ></AgGridColumn>
          <AgGridColumn
            field="Hidden"
            sortable={true}
            filter={true}
            cellRendererFramework={JobHiddenRenderer}
            cellRendererParams={{data: {}}}
            width={100}
          ></AgGridColumn>
          <AgGridColumn field="gLegal" type="nonEditableColumn" headerName="Creator(GC/R)" width={150}></AgGridColumn>
          <AgGridColumn
            headerName="Budget"
            field="Payment"
            sortable={true}
            filter={true}
            valueGetter={(params) => params.data.Payment.Overall}
            valueSetter={(params) => {
              params.data.Payment.Overall = params.newValue;
              if (params.data.Payment.phase2 && params.data.Payment.phase2) {
                params.data.Payment.phase3 = (params.data.Payment.Overall - params.data.Payment.phase1 -params.data.Payment.phase2).toString();
              }
              return params.newValue !== params.oldValue;
            }}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            headerName="Phase1"
            field="Payment"
            sortable={true}
            filter={true}
            valueGetter={(params) => params.data.Payment.phase1}
            valueSetter={(params) => {
              params.data.Payment.phase1 = params.newValue;
              if (params.data.Payment.phase2 && params.data.Payment.phase2) {
                params.data.Payment.phase3 = (params.data.Payment.Overall - params.data.Payment.phase1 -params.data.Payment.phase2).toString();
              }
              return params.newValue !== params.oldValue;
            }}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            headerName="Phase2"
            field="Payment"
            sortable={true}
            filter={true}
            valueGetter={(params) => params.data.Payment.phase2}
            valueSetter={(params) => {
              params.data.Payment.phase2 = params.newValue;
              if (params.data.Payment.phase2 && params.data.Payment.phase2) {
                params.data.Payment.phase3 = (params.data.Payment.Overall - params.data.Payment.phase1 -params.data.Payment.phase2).toString();
              }
              return params.newValue !== params.oldValue;
            }}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            headerName="Phase3"
            field="Payment"
            sortable={true}
            filter={true}
            valueGetter={(params) => params.data.Payment.phase3}
            type="nonEditableColumn"
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="Description"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field=" Location"
            sortable={true}
            filter={true}
            cellRendererFramework={LocationRenderer}
            cellRendererParams={{data: {}}}
            width={250}
          ></AgGridColumn>
          <AgGridColumn
            field="ServicesNeeded"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field="ZipCode"
            sortable={true}
            filter={true}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="File1"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{data: {}, type: "File1"}}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="File2"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{data: {}, type: "File2"}}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="File3"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{data: {}, type: "File3"}}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="File4"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{data: {}, type: "File4"}}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="File5"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{data: {}, type: "File5"}}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="File6"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{data: {}, type: "File6"}}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="Timestamp"
            headerName="Created At"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn field="UserUid" type="nonEditableColumn" headerName="Creator(GC/R) ID" width={150}></AgGridColumn>
        </AgGridReact>
      </div>
      <div className="flex items-center justify-evenly">
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onHideJobs}
          disabled={selectedRows.length === 0}
        >
          Hide Selected Jobs
        </button>
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onDeleteJobs}
          disabled={selectedRows.length === 0}
        >
          Delete Selected Jobs
        </button>
      </div>
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Do you really want to delete the selected jobs?
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onPressDelete}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default JobsAdminPage;
