import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const EmailCampaignPage = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [sent, setSent] = useState(false);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    setSent(false);
    if (name === "userEmail") {
      setEmail(value);
    } else if(name === "firstName") {
      setFirstName(value);
    }
  };

  const onPress = async (e) => {
    e.preventDefault();

    const msg = {
      personalizations: [
        {
          from: {
            email: "rey@subworknow.com",
            name: "SubWorkNow",
          },
          to: [
            {
              email: email,
              name: email,
            },
          ],
        },
      ],
      from: {
        email: "rey@subworknow.com",
        name: "SubWorkNow",
      },
      reply_to: {
        email: "rey@subworknow.com",
        name: "SubWorkNow",
      },
      subject: "Be Notified of Job Postings",
      content: [
        {
          type: "text/html",
          value: `<!doctype html>
          <html>
          
            <head>
              <meta name="viewport" content="width=device-width" />
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
              <title>Be Notified of Job Postings</title>
              <style>
                body {
                  background-color: #f6f6f6;
                  font-family: sans-serif;
                  -webkit-font-smoothing: antialiased;
                  font-size: 14px;
                  line-height: 1.4;
                  margin: 0;
                  padding: 0;
                  -ms-text-size-adjust: 100%;
                  -webkit-text-size-adjust: 100%;
                }
          
                table {
                  border-collapse: separate;
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  width: 100%;
                }
        
                table td {
                  font-family: sans-serif;
                  font-size: 14px;
                  vertical-align: top;
                }
          
                /* -------------------------------------
                              BODY & CONTAINER
                          ------------------------------------- */
        
                .body {
                  background-color: #f6f6f6;
                  width: 100%;
                }
        
                /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
                .container {
                  display: block;
                  margin: 0 auto !important;
                  /* makes it centered */
                  max-width: 580px;
                  padding: 10px;
                  width: 580px;
                }
        
                /* This should also be a block element, so that it will fill 100% of the .container */
                .content {
                  box-sizing: border-box;
                  display: block;
                  margin: 0 auto;
                  max-width: 580px;
                  padding: 10px;
                }
          
                /* -------------------------------------
                              HEADER, FOOTER, MAIN
                          ------------------------------------- */
                .main {
                  background: #ffffff;
                  border-radius: 3px;
                  width: 100%;
                }
        
                .wrapper {
                  box-sizing: border-box;
                  padding: 20px;
                }
        
                .content-block {
                  padding-bottom: 10px;
                  padding-top: 10px;
                }
          
                /* -------------------------------------
                              TYPOGRAPHY
                          ------------------------------------- */
                h1,
                h2,
                h3,
                h4 {
                  color: #000000;
                  font-family: sans-serif;
                  font-weight: 400;
                  line-height: 1.4;
                  margin: 0;
                  margin-bottom: 30px;
                }
        
                h1 {
                  font-size: 35px;
                  font-weight: 300;
                  text-align: center;
                  text-transform: capitalize;
                }
          
                p,
                ul,
                ol {
                  font-family: sans-serif;
                  font-size: 14px;
                  font-weight: normal;
                  margin: 0;
                  margin-bottom: 15px;
                }
        
                p li,
                ul li,
                ol li {
                  list-style-position: inside;
                  margin-left: 5px;
                }
        
                a {
                  color: #3498db;
                  text-decoration: underline;
                  cursor: pointer;
                }
        
                hr {
                  border: 0;
                  border-bottom: 1px solid #f6f6f6;
                  margin: 20px 0;
                }
          
                /* -------------------------------------
                              RESPONSIVE AND MOBILE FRIENDLY STYLES
                          ------------------------------------- */
                @media only screen and (max-width: 620px) {
                  table[class=body] h1 {
                    font-size: 28px !important;
                    margin-bottom: 10px !important;
                  }
      
                  table[class=body] p,
                  table[class=body] ul,
                  table[class=body] ol,
                  table[class=body] td,
                  table[class=body] span,
                  table[class=body] a {
                    font-size: 16px !important;
                  }
      
                  table[class=body] .wrapper,
                  table[class=body] .article {
                    padding: 10px !important;
                  }
      
                  table[class=body] .content {
                    padding: 0 !important;
                  }
          
                  table[class=body] .container {
                    padding: 0 !important;
                    width: 100% !important;
                  }
      
                  table[class=body] .main {
                    border-left-width: 0 !important;
                    border-radius: 0 !important;
                    border-right-width: 0 !important;
                  }
      
                  table[class=body] .btn table {
                    width: 100% !important;
                  }
      
                  table[class=body] .btn a {
                    width: 100% !important;
                  }
      
                  table[class=body] .img-responsive {
                    height: auto !important;
                    max-width: 100% !important;
                    width: auto !important;
                  }
                }
              </style>
            </head>
          
            <body>
              <span class="preheader"></span>
              <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
                <tr>
                  <td>&nbsp;</td>
                  <td class="container">
                    <div class="content">
          
                      <!-- START CENTERED WHITE CONTAINER -->
                      <table role="presentation" class="main">
  
                        <!-- START MAIN CONTENT AREA -->
                        <tr>
                          <td class="wrapper">
                            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                              <tr>
                                <td>
                                  <p>Hi ${firstName},</p>
                                  <p>Did you possibly have time to take a look at the new SubWorkNow.com platform that allows Small Sub-Contractors to locate and apply for open jobs and projects? We know running a business creates time constraints so if you would simply like to be notified about open jobs in your area simply press here:</p>
                                  <p style="padding: 10px 0;">
                                    <a href="https://subworknow-web.com/emailnotify/${email}" style="background: #3498db; color: white; text-decoration: none; border-radius: 5px; padding: 10px;">Be Notified of Job Postings</a>
                                  </p>
                                  <p>If selected we will send you an email when applicable jobs and projects open in your area.</p>
                                  <br />
                                  <p>Rey De Los Santos<br/>Project Manager</p>
                                  <p>
                                    <img src="https://i.ibb.co/b5WpDdv/logo.png" alt="logo" border="0" style="width: 100px; height: 100px;">
                                  </p>
                                  <p>
                                    <a href="https://subworknow-web.com/" style="font-weight: bold;">SubWorkNow.com</a><br/>
                                    <a href="mailto:rey@subworknow.com">rey@subworknow.com</a><br/>
                                    <a href="https://www.linkedin.com/in/rey-de-los-santos-95820b205/">linkedin.com</a>
                                  </p>
                                  <p>
                                    <div style="font-weight: bold;">Fort Worth, TX Office</div>
                                    2883 Crockett St. #138<br/>Fort Worth, Texas 76107
                                  </p>
                                  <p>
                                    <div style="font-weight: bold;">San Antonio, TX Office</div>
                                    8940 Fourwinds Dr. #135<br/>Windcrest, Texas 78239
                                  </p>
                                  <p>1-844-SUBWORK1</p>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
  
                        <!-- END MAIN CONTENT AREA -->
                      </table>
                      <!-- END CENTERED WHITE CONTAINER -->
          
                    </div>
                  </td>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </body>
          
          </html>
          `,
        },
      ],
    };

    axios
      .post(
        "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
        msg,
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function (response) {
        if (response.status === 200) {
          setSent(true);
        }
      })
      .catch(function (error) {
        setSent(false);
      });
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4 px-8">
        <div className="flex items-center mt-10">
          <label htmlFor="firstName" className="block w-24">
            First Name:
          </label>
          <input
            type="text"
            className="my-1 p-2 w-64 border border-black-400 rounded"
            name="firstName"
            value={firstName}
            placeholder="Please write a SC First Name here!"
            id="firstName"
            onChange={(event) => onChangeHandler(event)}
          />
        </div>
        <div className="flex items-center mt-2">
          <label htmlFor="userEmail" className="block w-24">
            SC Email:
          </label>
          <input
            type="email"
            className="my-1 p-2 w-64 border border-black-400 rounded"
            name="userEmail"
            value={email}
            placeholder="Please write a SC Email here!"
            id="userEmail"
            onChange={(event) => onChangeHandler(event)}
          />
        </div>
        <button
          className={`w-48 ${!!email && !!firstName ? "bg-primary" : "bg-gray-500"
            } text-white py-2 my-5 rounded-full focus:outline-none`}
          onClick={(event) => {
            onPress(event);
          }}
          disabled={!email || !firstName}
        >
          Send
        </button>
        {sent &&
          <p className="text-primary">Email is sent successfully.</p>
        }
      </div>
    </div>
  );
};

export default EmailCampaignPage;
