import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBEh4faZR1oYiRs7jto0LcUxUV3Z0Cz7Yo",
  authDomain: "subworknow.firebaseapp.com",
  databaseURL: "https://subworknow-default-rtdb.firebaseio.com",
  projectId: "subworknow",
  storageBucket: "subworknow.appspot.com",
  messagingSenderId: "24180396934",
  appId: "1:24180396934:web:388f9adf70b656f3285207",
  measurementId: "G-NKR9YZMD3N"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const database = firebase.database();
export const storage = firebase.storage();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();