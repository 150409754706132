import React from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";

const ContactUsPage = () => {
  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        <p className="mt-3 text-xl">
          Email us to &nbsp;
          <a
            className="text-blue-500 underline"
            href="mailto:rey@subworknow.com"
          >
            rey@subworknow.com
          </a>
          &nbsp;or call &nbsp;
          <a className="text-blue-500 underline" href="tel:1-844-782-6691">
            1-844-SUBWORK1 (1-844-782-6691)
          </a>
          &nbsp;with any questions or inqueries.
        </p>
        <Link
          to="#"
          onClick={(e) => {
            window.location = "mailto:rey@subworknow.com";
            e.preventDefault();
          }}
          className="bg-green-400 hover:bg-green-500 w-40 py-2 text-white text-center mt-20 rounded-3xl"
        >
          Send Email
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            window.location = "tel:1-844-782-6691";
            e.preventDefault();
          }}
          className="bg-green-400 hover:bg-green-500 w-40 py-2 text-white text-center mt-4 rounded-3xl"
        >
          Phone Call
        </Link>
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          className="bg-green-400 hover:bg-green-500 w-40 py-2 text-white text-center mt-4 rounded-3xl"
        >
          Messaging
        </Link>
      </div>
    </div>
  );
};

export default ContactUsPage;
