import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import HomePage from "../pages/HomePage";
import PasswordReset from "../pages/PasswordReset";
import AMPage from "../pages/AMPage";
import ContactUsPage from "../pages/ContactUsPage";
import AssistantPage from "../pages/AssistantPage";
import TermsPage from "../pages/TermsPage";
import SCUsersPage from "../pages/SCUsersPage";
import GCUsersPage from "../pages/GCUsersPage";
import JobsAdminPage from "../pages/JobsAdminPage";
import W9AdminPage from "../pages/W9AdminPage";
import OffersPage from "../pages/OffersPage";
import AUsersPage from "../pages/AUsersPage";
import LocationsPage from "../pages/LocationsPage";
import ViewSCPage from "../pages/ViewSCPage";
import EmailCampaignPage from "../pages/EmailCampaignPage";
import LandingPage from "../pages/LandingPage";
import LandingSuccessPage from "../pages/LandingSuccessPage";
import RUsersPage from "../pages/RUsersPage";

function PublicRoute() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/passwordreset" component={PasswordReset} />
        <Route exact path="/assistant" component={AssistantPage} />
        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/contactus" component={ContactUsPage} />
        <Route exact path="/emailcampaign" component={EmailCampaignPage} />
        <Route exact path="/landingpage" component={LandingPage} />
        <Route exact path="/landingsuccess" component={LandingSuccessPage} />
        <PrivateRoute exact path="/accountmanagement" component={AMPage} />
        <PrivateRoute exact path="/scUsers" component={SCUsersPage} />
        <PrivateRoute exact path="/gcUsers" component={GCUsersPage} />
        <PrivateRoute exact path="/rUsers" component={RUsersPage} />
        <PrivateRoute exact path="/jobsAdmin" component={JobsAdminPage} />
        <PrivateRoute exact path="/w9Admin" component={W9AdminPage} />
        <PrivateRoute exact path="/offers" component={OffersPage} />
        <PrivateRoute exact path="/aUsers" component={AUsersPage} />
        <PrivateRoute exact path="/locations" component={LocationsPage} />
        <PrivateRoute exact path="/viewsc" component={ViewSCPage} />
      </Switch>
    </Router>
  );
}

export default PublicRoute;
