import React, {useState} from 'react';
import { database } from '../providers/firebase';

const list = [
  "Austin, TX Metro Area",
  "Dallas, TX Metro Area",
  "Fort Worth, TX Metro Area",
  "Houston, TX Metro Area",
  "Oklahoma City, OK Metro Area",
  "Portland, OR Metro Area",
  "San Antonio, TX Metro Area",
  "Seattle, WA Metro Area",
];

const LocationRenderer = ({data}) => {
  const[selectedValue, setSelectedValue] = useState(data.Location || '');

  const onChange = (event) => {
    setSelectedValue(event.target.value);

    const gcUserRef = database.ref(`JT/${data.JobId}`);
    gcUserRef.update({
      Location: event.target.value
    });
  }

  return(
    <div>
      <select value={selectedValue} onChange={onChange}>
        {list.map((item) => <option value={item} key={item}>{item}</option>)}
      </select>
    </div>
  )
}

export default LocationRenderer;
