import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { database, firestore } from "../providers/firebase";
import Modal from "react-modal";
import IconRenderer from "../components/IconRenderer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const defaultColDef = {
  resizable: true,
  editable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  valueSetter: (params) => {
    params.data[params.colDef.field] = params.newValue;
    return params.newValue !== params.oldValue;
  },
  valueGetter: (params) => params.data[params.colDef.field],
};

const columnTypes = {
  nonEditableColumn: { editable: false },
};

const rowHeight = 44;

const RUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen1, setIsOpen1] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let rUsers = [];
      const snapshot = await database.ref(`RT`).once("value");
      if (!!snapshot.val()) {
        snapshot.forEach((child) => {
          rUsers.push(child.val());
        });
      }

      rUsers.sort((a,b) => (a.JoinedAt < b.JoinedAt) ? 1 : ((b.JoinedAt < a.JoinedAt) ? -1 : 0))
      setUsers(rUsers);
    }

    fetchData();
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onCellValueChanged = (event) => {
    if (event.data.UserUid) {
      const rUserRef = database.ref(`RT/${event.data.UserUid}`);
      rUserRef.update(event.data);
    } else if (event.data.Email) {
      axios
        .post(
          "https://us-central1-subworknow.cloudfunctions.net/addUser",
          {email: event.data.Email, password: "123456"},
          { headers: { "Content-Type": "application/json" } }
        )
        .then(async function (response) {
          console.log('response', response);
          const rUserRef = database.ref(`RT/${response.data.data.uid}`);
          await rUserRef.set({
            UserUid: response.data.data.uid,
            Email: event.data.Email,
            JoinedAt: new Date().toISOString(),
          });
          toast('The new user has been created.');
        })
        .catch(function (error) {
          console.log('error', error);
        });
    }
  };

  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    setSelectedRows(selectedRows);
  };

  const onHideUsers = async () => {
    selectedRows.forEach(async (row) => {
      const key = row.UserUid;

      if (key) {
        await database.ref(`RT/${key}`).update({hidden: true});
        toast('This user is hidden now.')
      }
    });
  }

  const onPressDelete = async () => {
    gridApi.applyTransaction({ remove: selectedRows });

    let jobs = [];
    const snapshot2 = await database.ref(`JT`).once("value");
    if (!!snapshot2.val()) {
      snapshot2.forEach((child) => {
        jobs.push(child.val());
      });
    }

    let bids = [];
    const snapshot = await database.ref(`BT`).once("value");
    if (!!snapshot.val()) {
      snapshot.forEach((child) => {
        bids.push(child.val());
      });
    }

    let offers = [];
    const snapshot1 = await database.ref(`OT`).once("value");
    if (!!snapshot1.val()) {
      snapshot1.forEach((child) => {
        offers.push(child.val());
      });
    }
  
    selectedRows.forEach((row) => {
      const key = row.UserUid;
      database.ref(`RT/${key}`).remove();

      const filterJobs = jobs.filter(job => job.UserUid === key);
      if (filterJobs && filterJobs.length > 0) {
        filterJobs.forEach(job => {
          database.ref(`JT/${job.JobId}`).remove();

          const filterBids = bids.filter(bid => bid.JobId === job.JobId);
          if (filterBids && filterBids.length > 0) {
            filterBids.forEach(bid => {
              database.ref(`BT/${bid.BidId}`).remove();
            })
          }
        });
      }

      const filterOffers = offers.filter(offer => offer.UserUid === key);
      if (filterOffers && filterOffers.length > 0) {
        filterOffers.forEach(offer => {
          database.ref(`OT/${offer.OfferId}`).remove();
        })
      }
    });
    setIsOpen(false);
  };

  const onTransferUsers = () => {
    setIsOpen1(true);
  }

  const onDeleteUsers = () => {
    setIsOpen(true);
  };

  const onPressTransfer = () => {
    setIsOpen1(false);
    selectedRows.forEach(async (row) => {
      const key = row.UserUid;
      const scUserRef = database.ref(`SCT/${key}`);
      await scUserRef.set({
        Address: row.Address,
        Email: row.Email,
        IconUpload: row.IconUpload,
        JoinedAt: row.JoinedAt,
        LegalEntityName: row.LegalEntity,
        Phone: row.Phone,
        PlayerId: row.PlayerId,
        SecondEmail: row.SecondEmail,
        UserUid: key,
        fcmToken: row.fcmToken,
        NotifyJobs: row.NotifyJobs,
      });
    });

    onPressDelete();
  }

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const onCloseModal1 = () => {
    setIsOpen1(false);
  }

  const onCloseModal2 = () => {
    setIsOpen2(false);
  }

  const onPressAdd = () => {
    var newItems = [[]];
    var res = gridApi.applyTransaction({
      add: newItems,
      addIndex: 0,
    });
  }

  const onDelteGroupChat = () => {
    setIsOpen2(true);
  }

  const onPressDeleteGroupChat = async () => {
    setIsOpen2(false);
    let item = {};     

    for (item of users) {
      const querySnapshot = await firestore.collection(`Group-${item.UserUid}`).get();
      if (!!querySnapshot) {
        for (const doc of querySnapshot.docs) {
          firestore.collection(`Group-${item.UserUid}`).doc(doc.id).delete();
        }          
      }
    }
  }

  return (
    <div className="w-full">
      <Navbar />
      <p className="text-center font-bold mb-4">Realtors</p>
      <div className="ag-theme-alpine w-full h-96">
        <AgGridReact
          rowData={users}
          rowHeight={rowHeight}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          // rowSelection={"multiple"}
          rowMultiSelectWithClick={true}
          pivotPanelShow={"always"}
          paginationAutoPageSize={true}
          pagination={true}
          onCellValueChanged={onCellValueChanged}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
        >
          <AgGridColumn
            field="UserUid"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            checkboxSelection={true}
            width={150}
          ></AgGridColumn>
          <AgGridColumn
            field="Email"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field="LegalEntity"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field="PointOfContact"
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn field="Address"></AgGridColumn>
          <AgGridColumn field="SecondEmail"></AgGridColumn>
          <AgGridColumn field="Phone" width={150}></AgGridColumn>
          <AgGridColumn
            field="IconUpload"
            headerName="Icon"
            type="nonEditableColumn"
            cellRendererFramework={IconRenderer}
            cellRendererParams={{data: {}, type: "IconUpload"}}
            width={100}
          ></AgGridColumn>
          <AgGridColumn
            field="NotifyJobs"
            headerName="Notify Job Bids"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="JoinedAt"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
          <AgGridColumn
            field="PlayerId"
            type="nonEditableColumn"
            width={150}
          ></AgGridColumn>
          <AgGridColumn
            field="fcmToken"
            type="nonEditableColumn"
            width={150}
          ></AgGridColumn>
        </AgGridReact>
      </div>
      <div className="flex items-center justify-evenly">
        <button
          className={`w-48 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onPressAdd}
        >
          Add New User
        </button>
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onDeleteUsers}
          disabled={selectedRows.length === 0}
        >
          Delete Selected User
        </button>
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onTransferUsers}
          disabled={selectedRows.length === 0}
        >
          Transfer to SC
        </button>
        <button
          className={`w-48 ${
            selectedRows.length === 0 ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onHideUsers}
          disabled={selectedRows.length === 0}
        >
          Hide Selected User
        </button>
        <button
          className={`w-48 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={onDelteGroupChat}
        >
          Delete Group Chat
        </button>
      </div>
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Do you really want to delete the selected user?
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onPressDelete}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Modal isOpen={modalIsOpen1} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Do you really want to transfer this user to SC?
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onPressTransfer}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal1}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Modal isOpen={modalIsOpen2} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Do you really want to delete all of GC Group Chat?
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onPressDeleteGroupChat}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal2}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default RUsersPage;
