import React, {useState} from 'react';
import { database } from '../providers/firebase';

const list = ['True', 'False'];

const ContractDropdownRenderer = ({data, index}) => {
  const [replied, setReplied] = useState(data.isReplied ? 'True' : 'False');
  const [accepted, setAccepted] = useState(data.isAccepted ? 'True' : 'False');
  const [ended, setEnded] = useState(data.isEnded ? 'True' : 'False');
  const [funded, setFunded] = useState(data.funded ? 'True' : 'False');
  const [w9sent, setW9Sent] = useState(data.W9Sent ? 'True' : 'False' );

  const onChange = (event) => {
    if (index === 0) {
      setReplied(event.target.value);
    } else if (index === 1) {
      setAccepted(event.target.value);
    } else if (index === 2) {
      setEnded(event.target.value);
    } else if (index === 3) {
      setFunded(event.target.value);
    } else if (index === 4) {
      setW9Sent(event.target.value);
    }
    
    let tempValue = false;
    if (event.target.value == "True") tempValue = true;
    else if (event.target.value == "False") tempValue = false;
    else tempValue = false;

    const offerRef = database.ref(`OT/${data.OfferId}`);

    if (index === 0)
      offerRef.update({
        isReplied: tempValue
      });
    else if (index === 1)
      offerRef.update({
        isAccepted: tempValue
      });
    else if (index === 2)
      offerRef.update({
        isEnded: tempValue
      });
    else if (index === 3)
      offerRef.update({
        funded: tempValue
      });
    else if (index === 4)
      offerRef.update({
        W9Sent: tempValue
      });
  }

  return(
    <div>
      <select value={index === 0 ? replied : index === 1 ? accepted : index === 2 ? ended : index === 3 ? funded : w9sent} onChange={onChange}>
        {list.map((item) => <option value={item} key={item}>{item}</option>)}
      </select>
    </div>
  )
}

export default ContractDropdownRenderer;
