import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { database } from "../providers/firebase";

const defaultColDef = {
  resizable: true,
  editable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  valueSetter: (params) => {
    params.data[params.colDef.field] = params.newValue;
    return params.newValue !== params.oldValue;
  },
  valueGetter: (params) => params.data[params.colDef.field],
};

const columnTypes = {
  nonEditableColumn: { editable: false },
};

const rowHeight = 44;

const LocationsPage = () => {
  const [locations, setLocations] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let locations = [];
      const snapshot = await database.ref(`LT`).once("value");
      if (!!snapshot.val()) {
        snapshot.forEach((child) => {
          locations.push(child.val());
        });
      }

      locations.sort((a,b) => (a.Timestamp < b.Timestamp) ? 1 : ((b.Timestamp < a.Timestamp) ? -1 : 0))
      setLocations(locations);
    }

    fetchData();
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  return (
    <div className="w-full">
      <Navbar />
      <p className="text-center font-bold mb-4">Visited Locations</p>
      <div className="ag-theme-alpine w-full h-96">
        <AgGridReact
          rowData={locations}
          rowHeight={rowHeight}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          rowSelection={"multiple"}
          rowMultiSelectWithClick={true}
          onGridReady={onGridReady}
          pivotPanelShow={"always"}
          paginationAutoPageSize={true}
          pagination={true}
        >
          <AgGridColumn
            field="LocationId"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            width={150}
          ></AgGridColumn>
          <AgGridColumn
            field="Location"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
            width={600}
          ></AgGridColumn>
          <AgGridColumn
            field="Timestamp"
            sortable={true}
            filter={true}
            type="nonEditableColumn"
          ></AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  );
};

export default LocationsPage;
