import React, {useState} from 'react';
import { database } from '../providers/firebase';

const list = ['True', 'False'];

const DropdownRenderer = ({data}) => {
  const[selectedValue, setSelectedValue] = useState(data.Approved ? 'True' : 'False');

  const onChange = (event) => {
    setSelectedValue(event.target.value);
    let tempValue = false;
    if (event.target.value == "True") tempValue = true;
    else if (event.target.value == "False") tempValue = false;
    else tempValue = false;

    const gcUserRef = database.ref(`AT/${data.UserUid}`);
    gcUserRef.update({
      Approved: tempValue
    });
  }

  return(
    <div>
      <select value={selectedValue} onChange={onChange}>
        {list.map((item) => <option value={item} key={item}>{item}</option>)}
      </select>
    </div>
  )
}

export default DropdownRenderer;
