import React, {useState} from 'react';
import { database } from '../providers/firebase';

const list = [
  "",
  "Cabinet & Shelf Installation",
  "Cleaning Prep",
  "Door & Garage Installation",
  "Drywall & Plastering",
  "Electrical",
  "Excavation & Leveling",
  "Fencing",
  "Flooring",
  "Foundation, Cement & Concrete",
  "Framing",
  "Glass & Mirror",
  "HVAC",
  "Insulation",
  "Kitchen Appliance Installation",
  "Kitchen & Bathroom Counter Installation",
  "Lawn & Garden",
  "Masonry",
  "Painting",
  "Plumbing",
  "Post construction Cleaning",
  "Roofing",
  "Siding & Stucco",
  "Solar Installation",
];


const ServicesRenderer = ({data}) => {
  const[selectedValue, setSelectedValue] = useState(data.SelectQualifiedService || "");

  const onChange = (event) => {
    setSelectedValue(event.target.value);

    const scUserRef = database.ref(`SCT/${data.UserUid}`);
    scUserRef.update({
      SelectQualifiedService: event.target.value
    });
  }

  return(
    <div>
      <select value={selectedValue} onChange={onChange}>
        {list.map((item) => <option value={item} key={item}>{item}</option>)}
      </select>
    </div>
  )
}

export default ServicesRenderer;
