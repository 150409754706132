import React, {useEffect} from "react";
import Navbar from "../components/Navbar";
import landing from "../assets/image/SWN_Image.jpg";
import bgVideo from "../assets/video/SWN_bg.mov";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center pt-4">
        <img
          src={landing}
          alt="..."
          className="shadow max-w-full w-full h-auto align-middle border-none"
        />
        <video className="w-full h-auto" autoPlay muted loop>
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="flex flex-col px-10 py-10 items-center bg-gray-800 w-full text-center">
          <p className="lg:text-intro md:text-2xl text-base font-semibold text-white text-shadow lg:leading-10 w-3/4 md:w-2/4 lg:py-5">
            Enabling Small to Midsized General Contractors the Ability to Engage
            and Retain Qualified Subcontractors by Allowing the Posting of and
            Bidding on Jobs & Projects
          </p>
        </div>
        <div className="flex justify-around px-10 py-10 bg-primary w-full flex-col md:flex-row">
          <div className="text-white mb-4">
            <p className="font-bold">FORT WORTH, TX OFFICE</p>
            <p>2833 Crockett St. #138</p>
            <p>Fort Worth, Texas 76107</p>
            <p>1-844-SUBWORK1</p>
          </div>
          <div className="text-white mb-4">
            <p className="font-bold">MAILING ADDRESS</p>
            <p>1141 N. Loop 1604 E. #105 - 429</p>
            <p>San Antonio, Texas 78232</p>
          </div>
          <div className="text-white mb-4">
            <p className="font-bold">SAN ANTONIO, TX OFFICE</p>
            <p>8940 Fourwinds Dr. #135</p>
            <p>Windcrest, Texas 78239</p>
            <p>1-844-SUBWORK1</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
